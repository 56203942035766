<template>
  <select v-model="valueChild" class="custom-select">
    <!-- <option :value="undefined"></option> -->
    <option v-for="(l, key) in list" :key="key" :value="key">{{l}}</option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    list() {
      try {
        return this.$store.state.config.idcard.types;
      } catch (e) {
        return [];
      }
    }
  },
  methods: {
    setVal(idCard) {
      this.$emit("input", parseInt(idCard));
    }
  }
};
</script>

<style>
</style>
